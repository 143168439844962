import React, {Component, Suspense} from 'react';
import dynamic from 'next/dynamic';
import SlideToggle from 'react-slide-toggle';
import throttle from 'lodash/throttle';
import Block from '../../adapters/helpers/Block';
import HierarchyUtils from '../../adapters/helpers/HierarchyUtils';
import { FooterConstants } from '../../adapters/helpers/Constants';
import { stripHtml } from '../../adapters/helpers/Utils';
import { getPrivacyLink } from './PrivacyLink'
const Icon = dynamic(() => import('../Icon/Icon'));
const Image = dynamic(() => import('../Image/Image'));
const Eyebrow = dynamic(() => import('../Eyebrow/Eyebrow'));
import Link from 'next/link';
import Loader from '../../adapters/helpers/Loader';

class Footer extends Component {

    _desktopBreakpoint = 992;
    constructor(props) {
        super(props);
        this.ev = '';
        this.s = '';
        this.block = new Block(props);
        this.state = {
            width: 0,
            privacyLinkObj: {}
        };
    }

    componentDidMount() {
        window.addEventListener(FooterConstants.resize, this.handleResizeThrottled);
        this.handleResize();
        this.getPrivacyPolicyLink();
    }

    componentWillUnmount() {
        window.removeEventListener(FooterConstants.resize, this.handleResizeThrottled);
    }

    handleResize() {
        this.setState({
            width: window.innerWidth
        })
    }

    handleResizeThrottled = throttle(this.handleResize.bind(this), 100);

    renderHierarchy(hierarchy) {
        const destinations = hierarchy?.fields?.destinations;
        let title = hierarchy?.fields?.title;
        let isMainFooter = HierarchyUtils.getHierarchyId(hierarchy) === 'Hierarchy-Footer';
        const isCollapsible = destinations && destinations.length > 1;

        const setClassNames = dest => {
            let className = dest.fields.className ? dest.fields.className : ''
            if (dest.fields.onlyShowOn) {
                className += ` ob-footer__onlyShowOn--${dest.fields.onlyShowOn}`
            }
            if (dest.fields.contentType === 'hierarchy') {
                return 'ob-footer__list ' + className;
            } else if (isMainFooter) {
                return 'ob-footer__item ' + className;
            }
            return className;
        };

        const renderFooter = (callback = null, setCollapsibleElement = null, range = null, toggleState = null) => {
            const styles = range ? { opacity: Math.max(0, range) } : { opacity: 1 };
            return (
                <>
                <Suspense fallback={<Loader />}>
                    {title && this.renderHierarchyTitle(hierarchy, title, callback, isCollapsible, toggleState)}
                    {destinations && HierarchyUtils.getHierarchyId(hierarchy) !== 'Hierarchy-Footer-Utility' &&
                        <ul className='list-item-footer' id={HierarchyUtils.getHierarchyId(hierarchy)} ref={HierarchyUtils.getHierarchyId(hierarchy) !== 'Hierarchy-Footer-Top' && setCollapsibleElement}>
                        {
                            destinations.map((dest, index) => (
                                <li key={index} className={setClassNames(dest)} style={styles}>
                                    {
                                        dest?.fields?.image !== undefined ? (
                                            <Link href={dest.fields.url} target={dest.fields.type === 'external' ? '_blank' : undefined}
                                                rel={dest.fields.type === 'external' ? 'noopener noreferrer' : undefined}
                                                className={dest?.fields?.linkClassName}
                                                data-action-detail={stripHtml(dest?.fields?.title ? dest?.fields?.title : dest?.fields?.image?.fields?.alternateText)} >
                                                <Image noLazyLoad={true} image={dest.fields.image} noAltText={true}/>
                                                <span>{dest.fields.title}</span>
                                            </Link>
                                        ) : (
                                            HierarchyUtils.renderDestination(dest, this.renderHierarchy.bind(this, dest), '', '', '', this.state.privacyLinkObj)
                                        )
                                    }
                                </li>
                            ))
                        }
                        </ul>
                    }
                    {destinations && HierarchyUtils.getHierarchyId(hierarchy) === 'Hierarchy-Footer-Utility' &&
                        <ul className='list-item-footer' id={HierarchyUtils.getHierarchyId(hierarchy)}>
                        {
                            destinations.map((dest, index) => (
                                <li key={index} className={setClassNames(dest)} style={styles}>
                                    { HierarchyUtils.getHierarchyId(dest) === 'Link---Footer---CCPA' &&
                                        <span dangerouslySetInnerHTML={{__html: `
                                            <Link aria-label='${dest.fields.title}' href="javascript:void(0)" onclick="Optanon.ToggleInfoDisplay();">${dest.fields.title}</Link>
                                        `,}}>
                                        </span>
                                    }
                                    { HierarchyUtils.getHierarchyId(dest) !== 'Link---Footer---CCPA' && (
                                        dest?.fields?.image !== undefined ? (
                                            <Link href={dest.fields.url} target={dest.fields.type === 'external' ? '_blank' : undefined}
                                            rel={dest.fields.type === 'external' ? 'noopener noreferrer' : undefined}
                                            className={dest?.fields?.linkClassName}
                                            data-action-detail={stripHtml(dest?.fields?.title ? dest?.fields?.title : dest?.fields?.image?.fields?.alternateText)} >
                                                <Image noLazyLoad={true} image={dest.fields.image} />
                                                <span>{dest.fields.title}</span>
                                            </Link>
                                        ) : (
                                            HierarchyUtils.renderDestination(dest, this.renderHierarchy.bind(this, dest), '', '', '', this.state.privacyLinkObj)
                                        )
                                    )
                                    }
                                </li>
                            ))
                        }
                        <li dangerouslySetInnerHTML={{__html: `${FooterConstants.copyrightAt}${new Date().getFullYear()} ${this.block.getFieldValue(FooterConstants.copyright)}`}}></li>
                        </ul>
                    }
                    </Suspense>
                </>
            )
        };

        const renderCollapsibleFooter = () => {
            return (
                <SlideToggle
                    duration={500}
                    collapsed={!isMainFooter}>
                    {({ onToggle, setCollapsibleElement, range, toggleState }) => (
                        renderFooter(onToggle, setCollapsibleElement, range, toggleState)
                    )}
                </SlideToggle>
            )
        }

        return this.state.width <= this._desktopBreakpoint && isCollapsible ? renderCollapsibleFooter() : renderFooter();
    }

    renderHierarchyTitle(hierarchy, title, callback = null, isCollapsible = false, toggleState = null) {
        if (callback == null) {
            return (
                <div className="ob-footer__title-ctn">
                    <Eyebrow tag="h2" className="ob-footer__title">
                        <span>{title}</span>
                        {isCollapsible &&<span className={`ob-footer__title-icon ${toggleState === 'COLLAPSED' || toggleState === 'COLLAPSING' ? '' : 'ob-footer__title-icon--reversed'}`}><Icon name="fullChevronDown" viewBox={12} size={1.3} /></span>}
                    </Eyebrow>
                </div>
            );
        } else {
            return (
                <Eyebrow tag="h2" className="ob-footer__title-ctn">
                    <button onClick={callback} type="button" className="ob-footer__title" aria-expanded={toggleState === 'COLLAPSED' || toggleState === 'COLLAPSING' ? false : true}>
                        <span>{title}</span>
                        {isCollapsible &&<span className={`ob-footer__title-icon ${toggleState === 'COLLAPSED' || toggleState === 'COLLAPSING' ? '' : 'ob-footer__title-icon--reversed'}`}><Icon name="fullChevronDown" viewBox={12} size={1.3} /></span>}
                    </button>
                </Eyebrow>
            );
        }
        
    }
    async getPrivacyPolicyLink() {
        try {
            let link = await getPrivacyLink({ countryCode: FooterConstants.countryCode, lang: FooterConstants.lang, linkType: FooterConstants.privacyLink });
            if (typeof link === 'object'){
                this.setState({ privacyLinkObj: { ...this.state.privacyLinkObj, privacy: link?.link }})
            } else {
                this.setState({ privacyLinkObj: { ...this.state.privacyLinkObj, privacy: link || '' } })
            }            
        } catch(e) {
            this.setState({ privacyLinkObj: { privacy: '' } })    
        }
    }
    render() {
        const hierarchyDoc = this.block.getFieldValue('hierarchy');
        return (
            <div className='ob-footer'>
                {this.renderHierarchy(hierarchyDoc)}
            </div>
        )
    }
}

export default Footer
